
import {ICrudOption} from "@/types/m-ui-crud";
import {Component, Vue} from "vue-property-decorator";
import {
  systemRoleCreateApi,
  systemRoleDetailApi,
  systemRoleModifyApi,
  systemRoleQueryApi,
  systemRoleRemoveApi
} from "@/apis/systemManage/role";
import {ISystemRole, ISystemRoleQuery} from "@/apis/systemManage/role/types";
import {systemMenuQueryApi} from "@/apis/systemManage/menu";

/**
 * @name: 系统管理-角色管理
 * @author: lili
 * @date: 2023-07-19 10:08
 * @description： 系统管理-角色管理
 * @update: 2023-07-19 10:08
 */
@Component({})
export default class RolePage extends Vue {
  // crud配置
  crudOption: ICrudOption = {
    dialogWidth: '600px',
    addTitle: '新增角色',
    editTitle: '编辑角色',
    column: [
      {
        label: "角色编号",
        prop: "id",
        align: "left",
        addHide: true,
        editHide: true,
        viewHide: true,
      },
      {
        label: "角色名称",
        prop: "roleName",
        align: "center",
        span: 16,
        search: true,
        rules: [
          {required: true, message: "请输入角色名称", trigger: 'blur'}
        ],
        maxlength:"50"
      },
      {
        label: "状态",
        prop: "status",
        align: "center",
        span: 24,
        type: "switch",
        slot: true,
        value: 1,
        dicData: [
          {
            label: "禁用",
            value: 0,
          },
          {
            label: "启用",
            value: 1,
          },
        ],
        rules: [
          {required: true, message: "请选择状态", trigger: 'blur'}
        ]
      },
      {
        label: '菜单权限',
        prop: 'menuIdList',
        hide: true,
        rules: [
          {required: true, message: "请选择菜单权限", trigger: 'blur'}
        ],
        addSlot: true,
        editSlot: true
      },
      {
        label: "创建时间",
        prop: "addTime",
        align: "center",
        addHide: true,
        editHide: true,
        viewHide: true,
      },
      {
        label: "修改时间",
        prop: "editTime",
        align: "center",
        addHide: true,
        editHide: true,
        viewHide: true,
      }
    ],
  };
  query: ISystemRoleQuery = {
    page: 1,
    limit: 10
  }
  // 新增/编辑 表单
  form: Partial<ISystemRole> = {}
  // 数据总数
  total: number = 0
  // 角色列表
  data: ISystemRole[] = []
  // 菜单列表
  menuList: any = [];
  //选中的父级
  nodeList: any = []
  //所有的父级
  pList: any = []

  /**
   * 查询列表
   */
  getList() {
    systemRoleQueryApi(this.query).then(e => {
      this.total = e.total;
      this.data = e.list;
    })
  }

  /**
   * 切换状态
   */
  handleSwitch(row: ISystemRole, val: number) {
    systemRoleModifyApi({id: row.id, status: val} as ISystemRole).then(e => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      })
      this.getList()
    })
  }

  getMenuList() {
    return new Promise(resolve => {
      systemMenuQueryApi({}).then((e:any) => {
        this.menuList = e;

        e.forEach((item:any) => {
          this.pList.push(item.id);
          let l=0;
          if(item.children&&item.children)
          item.children.forEach((item2:any)=>{
            l=item2.children.length;
            if(l>0)
            this.pList.push(item2.id)
          })
        })
        resolve(null)
      })
    })
  }

  handleCheck(nodeObj: any, SelectedObj: any) {
    this.form.menuIdList = SelectedObj.checkedKeys
    this.nodeList = SelectedObj.halfCheckedKeys;
  }

  /**
   * 打开新增
   */
  async openAdd() {
    await this.getMenuList()
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
  }

  /**
   * 打开详情
   * @param row,index
   */
  async openEdit(row: ISystemRole, index: number) {
    await this.getMenuList()
    systemRoleDetailApi(row.id).then(e => {
      this.nodeList=[];
      let arr = e.menuIdList.filter((item: any) => {
        if (!this.pList.includes(item)) {
          return item;
        }else {
          this.nodeList.push(item)
        }
      })
      let obj = e;
      obj.menuIdList = arr;
      // @ts-ignore
      this.$refs.crudRef.rowEdit(obj, index)
    })
  }

  /**
   * 新增
   * @param form
   */
  rowSave(form: ISystemRole, done: Function, loading: Function) {
    if (form.menuIdList)
      form.menuIdList = [...form.menuIdList, ...this.nodeList]
    systemRoleCreateApi(form).then(e => {
      done()
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    }).finally(() => {
      loading()
    })
  }

  /**
   * 修改
   * @param form
   */
  rowEdit(form: ISystemRole, done: Function, loading: Function) {
    if (form.menuIdList)
      form.menuIdList = [...form.menuIdList, ...this.nodeList]
    systemRoleModifyApi(form).then(e => {
      done()
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    }).finally(() => {
      loading()
    })
  }

  /**
   * 删除
   */
  rowDel(row: ISystemRole, index: number) {
    systemRoleRemoveApi(row.id).then(e => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    })
  }

  mounted() {
    this.getList()
  }
}
